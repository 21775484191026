var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('v-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('header',{staticClass:"header"},[_c('img',{attrs:{"src":require("./img/header_bg.png"),"alt":"图片加载失败..."}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"nav"},_vm._l((_vm.navList),function(item){return _c('div',{key:item.key,class:{ 'nav-index': _vm.navKey === item.key },on:{"click":function($event){return _vm.setNavKey(item.key)}}},[_c('span',[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"round"})])}),0),_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"list-content",on:{"click":function($event){return _vm.toRouteTitle(item)}}},[_c('div',{staticClass:"list-sidebar"},[_c('img',{attrs:{"src":item.imageUrls,"alt":""}})]),_c('div',{staticClass:"list-wrapper"},[_c('div',{staticClass:"left-wrap_top"},[_c('div',{staticClass:"left-title"},[_c('span',[_vm._v(_vm._s(item.title || ""))])]),_c('div',{staticClass:"endTime"},[_c('span',[_vm._v("结束时间：")]),_c('span',[_vm._v(_vm._s(item.endDate))])])]),_c('div',{staticClass:"left-wrap_bottom"},[(item.quStatus)?_c('div',{staticClass:"iconTag",style:({
                  background: _vm.textMap[item.quStatus][1],
                  color: _vm.textMap[item.quStatus][2],
                  borderColor: _vm.textMap[item.quStatus][3],
                })},[_c('span',[_vm._v(_vm._s(_vm.textMap[item.quStatus][0]))])]):_vm._e()]),(_vm.textMate(item, 'btnText'))?_c('div',{staticClass:"left-btn",style:(_vm.textMate(item, 'style'))},[_c('span',[_vm._v(_vm._s(_vm.textMate(item, "btnText")))])]):_vm._e()])])}),0)],1)]),(_vm.isDialog)?_c('div',{staticClass:"black_layer"},[_c('div',{staticClass:"dialog_box"},[_vm._m(0),_c('p',{staticClass:"bottom_message"},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.text1))]),_c('span',[_vm._v(_vm._s(_vm.text2))]),(
              (_vm.userInfo.identity == 0 || _vm.userInfo.identity == 1) &&
              _vm.dialogType == -1
            )?_c('span',{staticStyle:{"color":"#327eff"},on:{"click":function($event){return _vm.goBinding()}}},[_vm._v("前往绑定")]):_vm._e(),(
              (_vm.userInfo.identity == 0 || _vm.userInfo.identity == 1) &&
              _vm.dialogType == 2
            )?_c('span',{staticStyle:{"color":"#901818"},on:{"click":function($event){return _vm.goBinding()}}},[_vm._v("审核失败")]):_vm._e(),(
              (_vm.userInfo.identity == 0 || _vm.userInfo.identity == 1) &&
              _vm.dialogType == 0
            )?_c('span',{staticStyle:{"color":"#ffb92d"}},[_vm._v("审核中")]):_vm._e()])]),_c('img',{attrs:{"src":require("./img/close_icon.png"),"alt":""},on:{"click":function($event){_vm.isDialog = false}}})])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"top_message"},[_vm._v(" 涉及小区重大投票的表决权，"),_c('br'),_vm._v(" 仅限于产权人及其配偶才有投票权 ")])
}]

export { render, staticRenderFns }